import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import Autosuggest from 'react-autosuggest';

import CheckIcon from '@images/check.svg';

import './autosuggest-input.scss';

const AutosuggestInput = ({ label, input, meta, help, required, placeholder = '- введите -', choice, rightIcon, primary, secondary }) => {
    const { value, onChange, onFocus, onBlur, name } = input;
    const { touched, valid, error, submitError } = meta;

    const helpText = (touched && (error || submitError)) || help;

    const inputProps = useMemo(
        () => ({
            id: name,
            name,
            placeholder,
            value,
            onChange,
            onFocus,
            onBlur,
            className: classNames('base-input string-input__input', {
                //filled: !!value,
                'local-error': touched && error,
            }),
        }),
        [error, name, onBlur, onChange, onFocus, placeholder, touched, value],
    );

    const [suggestions, setSuggestions] = useState([]);

    const getSuggestions = useCallback(
        value => {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;

            return inputLength === 0 ? [] : choice.filter(lang => lang.label.toLowerCase().includes(inputValue));
        },
        [choice],
    );

    const onSuggestionsFetchRequested = useCallback(({ value }) => setSuggestions(getSuggestions(value)), [getSuggestions]);

    const onSuggestionsClearRequested = useCallback(() => setSuggestions([]), []);

    const getSuggestionValue = useCallback(suggestion => suggestion.label, []);

    const renderSuggestion = useCallback(suggestion => <div>{suggestion.label}</div>, []);

    return (
        <div className='autosuggest-input' data-test-id={['data-test-id']}>
            <label htmlFor={name} className='form__label'>
                {label}
            </label>
            <div
                className={classNames('string-input__input-wrap', {
                    'string-input__input--primary': primary,
                    'string-input__input--necessarily': required,
                    'string-input__input--filled': !!value,
                    'string-input__input--done': touched && valid,
                    'string-input__input--error': touched && !valid,
                    'string-input__input--secondary': secondary,
                })}
            >
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={(event, { suggestion, suggestionValue }) => {
                        event.stopPropagation();
                        onChange(suggestionValue);
                    }}
                    inputProps={inputProps}
                />
                {rightIcon && <div className='string-input__right-icon'>{rightIcon || (touched && !error && <CheckIcon />)}</div>}
            </div>
            {helpText && <div className={classNames('string-input__help', { 'string-input__help_warning': touched && !valid })}>{helpText}</div>}
        </div>
    );
};

export default AutosuggestInput;
